import './App.css';
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe("pk_live_Nlmp5qIZwIhI0BLEQ7VzIPYR");

function App() {

  let dollars = "0";
  let cents = "00";
  let paymentName = "Name / Payment Info";

  return (
    <div className="App">
        <div className="pictureArea"><img src="https://trxctiming.com/liveresults.trxctiming.com/images/TRXC.png" className="trxcImage" alt=""></img></div>
        <div className="nameArea"><input className="nameField" defaultValue={paymentName} onChange={e => paymentName = e.target.value}></input></div>
        <div className="paymentAmount">
            <input className="dollarsField" defaultValue={dollars} onChange={e => dollars = e.target.value}></input>
            <p>.</p>
            <input className="centsField" defaultValue={cents} onChange={e => cents = e.target.value}></input>
            <p>$</p>
        </div>
        <div className="buttonArea"><button className="payButton" onClick={() => handlePayment(dollars, cents, paymentName)}>PROCEED</button></div>
        <ToastContainer />
    </div>
  );
}

async function handlePayment(_dollars_, _cents_, _paymentName_) {

    let dollarsInt = parseInt(_dollars_);
    let centsInt = parseInt(_cents_);
    if(centsInt=>0 && centsInt<100){
        if(dollarsInt>0 && dollarsInt<1000000){
            let amount = dollarsInt*100 + centsInt;

            if(_paymentName_!=="Name - Payment Info" && _paymentName_.length>5){
                axios.get("https://liveresults.trxctiming.com/api/newPayment", { params: {amount: amount, name: _paymentName_} }).then((response) => {
                    setPayment(response.data.id)
                });
            }else{
                toast("Check name and information provided.");
            }
        }else {
            toast("Check $ amount provided.");
        }
    }else{
        toast("Check $ amount provided.");
    }
};

async function setPayment(id) {

    const stripe = await stripePromise;
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
        sessionId: id,
    });
    if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
    }
};



export default App;
